ul {
  margin-left: 50;
  font-size: 20px;
}
li {
  list-style: disc;
}
strong {
  color: black;
  font-weight: bold;
}
p {
  color: black;
  font-weight: 500;
}
